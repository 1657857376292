import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AssignSitesToSensorTypesComponent } from './assign-sites-to-sensor-types/assign-sites-to-sensor-types.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CustomerComponent, NewCustomerDialogComponent } from './customer/customer.component';
import { CustomerdetailsComponent, GetAppIdDialogComponent } from './customerdetails/customerdetails.component';
import { HardwareSearchFieldComponent } from './hardware-search-field/hardware-search-field.component';
import { HardwareTableComponent } from './hardware-table/hardware-table.component';
import {
  BulkAddHardwareEventComponent,
  HardwareOverviewComponent,
  NewHardwareDeviceComponent,
} from './hardware/hardwareoverview.component';
import { HardwareDetailsComponent } from './hardwaredetails/hardwaredetails.component';
import { MaterialImports } from './imports/material.importmodule';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { NewModbusConfigDialogComponent, SensorTypesComponent } from './sensor-type-definitions/sensorTypes.component';
import { Broadcaster } from './services/broadcast.service';
import { CustomerService } from './services/customer.service';
import { EdgeDeviceService } from './services/edgeDevice.service';
import { HardwareService } from './services/hw.service';
import { SensorTypeService } from './services/sensorType.service';
import { SiteService } from './services/site.service';
import { SiteConfigService } from './services/siteConfig.service';
import {
  ConnectEdgeDeviceComponent,
  SitedetailsEdgedevicesComponent,
} from './sitedetails-edgedevices/sitedetails-edgedevices.component';
import {
  ConnectSensorComponent,
  SitedetailsSensorsComponent,
} from './sitedetails-sensors/sitedetails-sensors.component';
import {
  ConnectTagComponent,
  InputListOfMacsComponent,
  SitedetailsTagsComponent,
} from './sitedetails-tags/sitedetails-tags.component';
import { SitedetailsComponent } from './sitedetails/sitedetails.component';
import { SetSiteReleaseTagConfigDialogComponent, SitesComponent } from './sites/sites.component';
import { StatusComponent } from './status/status.component';

export function loggerCallback(logLevel, message) {
  console.log('client logging' + message);
}
const appRoutes: Routes = [
  { path: 'customer', component: CustomerComponent, canActivate: [MsalGuard] },
  { path: 'customer/:customerId', component: CustomerdetailsComponent }, // ,canActivate:[AuthGuardService]},
  { path: 'customer/:customerId/site/:siteId', component: SitedetailsComponent }, // ,canActivate:[AuthGuardService]},
  {
    path: 'customer/:customerId/site/:siteId/:tabName',
    component: SitedetailsComponent,
    children: [{ path: ':id', component: HardwareDetailsComponent }],
  },
  { path: 'sites', component: SitesComponent, canActivate: [MsalGuard] },
  {
    path: 'hw',
    component: HardwareOverviewComponent,
    canActivate: [MsalGuard],
    children: [{ path: ':id', component: HardwareDetailsComponent }],
  },
  { path: 'sensor-type-definitions', component: SensorTypesComponent, canActivate: [MsalGuard] },
  { path: 'status', component: StatusComponent, canActivate: [MsalGuard] },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: '', redirectTo: '/customer', pathMatch: 'full' },
  { path: '**', component: CustomerComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    StatusComponent,
    CustomerComponent,
    PagenotfoundComponent,
    AuthCallbackComponent,
    CustomerdetailsComponent,
    SitedetailsComponent,
    GetAppIdDialogComponent,
    HardwareOverviewComponent,
    HardwareDetailsComponent,
    NewCustomerDialogComponent,
    ConnectEdgeDeviceComponent,
    ConnectSensorComponent,
    ConnectTagComponent,
    InputListOfMacsComponent,
    SitedetailsSensorsComponent,
    SitedetailsTagsComponent,
    HardwareSearchFieldComponent,
    ConfirmDialogComponent,
    NewHardwareDeviceComponent,
    BulkAddHardwareEventComponent,
    HardwareTableComponent,
    SitedetailsEdgedevicesComponent,
    SensorTypesComponent,
    AssignSitesToSensorTypesComponent,
    NewModbusConfigDialogComponent,
    SitesComponent,
    SetSiteReleaseTagConfigDialogComponent,
  ],
  imports: [
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.authentication.clientId,
          authority: environment.authentication.authority + '/' + environment.authentication.tenantId,
          redirectUri: environment.authentication.redirectUri,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      }),
      {
        interactionType: InteractionType.Redirect,
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [environment.apiAddress, [`${environment.authentication.resource}/admin`]],
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ]),
      }
    ),
    BrowserModule,
    FormsModule,
    MatDialogModule,
    MatSelectModule,
    MatPaginatorModule,
    MatGridListModule,
    MatSortModule,
    MatTooltipModule,
    MaterialImports,
    MatFormFieldModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxMatSelectSearchModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    Broadcaster,
    CustomerService,
    SiteService,
    HardwareService,
    SiteConfigService,
    EdgeDeviceService,
    SensorTypeService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
