import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Site, SiteService, SiteState } from '../services/site.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.css'],
})
export class SitesComponent implements OnInit {
  sitesTableSource: MatTableDataSource<Site> = new MatTableDataSource();
  sites: Site[];
  targetConditions: string[];
  constructor(private siteService: SiteService, public dialog: MatDialog, public snackBar: MatSnackBar) {}

  displayedColumns = [
    'select',
    'id',
    'customer',
    'name',
    'state',
    'releaseTag',
    'connectPOB',
    'gangway',
    'connectFuel',
    'cargoConditionMonitoring',
    'comment',
  ];

  filters = {
    prod: true,
    pending: true,
    dev: false,
    discontinued: false,
    demo: false,
    searchString: '',
  };

  ngOnInit() {
    this.fetchSites();
    this.fetchTargetConditions();
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.sitesTableSource.sort = this.sort;
  }

  filterSites() {
    this.sitesTableSource.data = this.sites.filter(
      (s) =>
        ((this.filters.prod && s.state == SiteState.production) ||
          (this.filters.pending && s.state == SiteState.pending) ||
          (this.filters.dev && s.state == SiteState.development) ||
          (this.filters.demo && s.state == SiteState.demo) ||
          (this.filters.discontinued && s.state == SiteState.discontinued)) &&
        (this.filters.searchString == '' ||
          s.name.toLowerCase().includes(this.filters.searchString.toLowerCase()) ||
          s.state.toLowerCase().includes(this.filters.searchString.toLowerCase()) ||
          s.releaseTag.toLowerCase().includes(this.filters.searchString.toLowerCase()) ||
          s.customer.name.toLowerCase().includes(this.filters.searchString.toLowerCase()))
    );
  }

  public selected = new SelectionModel<Site>(true, []);
  isAllSelected() {
    const numSelected = this.selected.selected.length;
    const numRows = this.sitesTableSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.selected.clear();
    } else {
      this.sitesTableSource.data.forEach((hd) => this.selected.select(hd));
    }
  }

  fetchSites(): void {
    this.siteService.getSites().subscribe(
      (s) => {
        this.sites = s;
        this.sitesTableSource.data = s;
        this.filterSites();
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open(`There was an error fetching sites ${err?.message ?? err}`, null, {
          duration: 10000,
        });
        console.error(err);
      }
    );
  }

  fetchTargetConditions(): void {
    this.siteService.getValidDeploymentTargetConditions().subscribe(
      (s) => {
        this.targetConditions = s;
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open(`There was an error fetching targetConditions ${err?.message ?? err}`, null, {
          duration: 10000,
        });
        console.error(err);
      }
    );
  }

  openDialog(): void {
    console.debug('OpenDialog triggered');

    const previousReleaseTagsForSelectedSites = Array.from(new Set(this.selected.selected.map((s) => s.releaseTag)));
    const dialogRef = this.dialog.open(SetSiteReleaseTagConfigDialogComponent, {
      width: '800px',
      data: {
        prefilledReleaseTag:
          previousReleaseTagsForSelectedSites.length == 1 ? previousReleaseTagsForSelectedSites[0] : null,
        selectedSites: this.selected.selected,
        validTargetConditions: this.targetConditions,
      },
    });
    dialogRef.afterClosed().subscribe((success = false) => {
      if (success) {
        this.fetchSites();
        this.selected.clear();
      }
    });
  }
}

@Component({
  selector: 'sites-set-release',
  template: `<mat-card appearance="outlined">
    <h3>Set release tag for selected sites</h3>
    <p>
      Input will autocomplete with real releaseTags from IotHub deployments. Selecting another releaseTag will probably
      not work, as there are no deployment manifest in IotHub targeting this releaseTag.
    </p>
    <form>
      <mat-form-field appearance="fill">
        <mat-label>ReleaseTag</mat-label>
        <input
          matInput
          type="string"
          name="releaseTag"
          placeholder="ReleaseTag"
          aria-label="ReleaseTag"
          [ngModel]="releaseTag | async"
          (ngModelChange)="releaseTag.next($event)"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let tag of validTargetConditions | async" [value]="tag">
            {{ tag }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-card-actions>
        <button mat-raised-button color="primary" [disabled]="!releaseTag" (click)="onSaveClick()">Save</button>
        <button mat-raised-button (click)="onCancelClick()">Cancel</button>
      </mat-card-actions>
    </form>
    <h4>Selected sites</h4>
    <ul>
      <li *ngFor="let site of selectedSites">{{ site.name }}</li>
    </ul>
  </mat-card> `,
})
export class SetSiteReleaseTagConfigDialogComponent implements OnInit {
  releaseTag: BehaviorSubject<string>;
  selectedSites: Site[];
  validTargetConditions: Observable<string[]>;
  constructor(
    public dialogRef: MatDialogRef<SetSiteReleaseTagConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { selectedSites: Site[]; prefilledReleaseTag?: string; validTargetConditions: string[] },
    private siteService: SiteService,
    public snackBar: MatSnackBar
  ) {
    this.releaseTag = new BehaviorSubject(data.prefilledReleaseTag ?? '');
    this.selectedSites = data.selectedSites;
  }

  ngOnInit() {
    this.validTargetConditions = this.releaseTag.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.data.validTargetConditions.filter((tag) => this._normalizeValue(tag).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  async onSaveClick(): Promise<void> {
    try {
      // Update all sites in parrallel
      const results = await Promise.allSettled(
        this.selectedSites.map((s) => this.updateReleaseTagForSite(s, this.releaseTag.getValue()))
      );

      // Check if all sites were updated
      let errorMessages = [];
      results.forEach((result) => {
        if (result.status == 'rejected') {
          console.error('Update releaseTag failure: ', result.reason);
          errorMessages.push(result.reason);
        }
      });
      if (errorMessages.length > 0) {
        this.snackBar.open(`There was an error updating releaseTag ${errorMessages.join(', ')}`, null, {
          duration: 10000,
        });
      } else {
        this.snackBar.open(
          `Updated releaseTag ${this.releaseTag.getValue()} for ${this.selectedSites.length} sites`,
          null,
          {
            duration: 10000,
          }
        );
        this.dialogRef.close(true);
      }
    } catch (err) {
      this.snackBar.open(`Error updating sites: ${err?.message ?? err}`, null, {
        duration: 10000,
      });
    }
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }

  updateReleaseTagForSite(site: Site, releaseTag: string) {
    return this.siteService.putSite(site.id, { ...site, releaseTag: releaseTag }).toPromise();
  }
}
